<template>
  <div class="media-app">
    <header>
      <ul class="tabs">
        <li class="active" style="margin: 0 68px" @click="changeTab(1)">
          照片
        </li>
        <li @click="changeTab(2)">视频</li>
      </ul>
      <Upload
        :data="MediaData"
        :headers="{ token: token }"
        name="file"
        :action="MediaUrl"
        :format="mediaFormat"
        :on-format-error="handleFormatError"
        :on-success="addMediaSuccess"
        :on-error="addMediaError"
        :disabled="uploadDisabled"
        :on-progress="onProgress"
        :show-upload-list="false"
      >
        <div class="tab-btn">上传</div>
      </Upload>
    </header>
    <div class="finish" v-if="beUploading">
      <p>{{ finish ? '正在解析文件,请等待' : '正在上传文件,请勿关闭窗口!' }}</p>
      <Progress
        :percent="percent"
        :stroke-width="25"
        style="width: 500px"
        status="active"
        text-inside
      />
    </div>
    <!-- 照片 -->
    <div v-if="MediaData.type === 1">
      <div class="img-list" v-if="imgdata.length > 0">
        <ul>
          <viewer :images="imgdata">
            <li v-for="data in imgdata" :key="data.id">
              <img :src="data.path" />
              <p class="media-date">{{ data.create_time }}</p>
              <Button
                type="error"
                size="small"
                shape="circle"
                class="delPicBtn"
                @click.stop="delMedia(data.id)"
                icon="md-close"
              >
              </Button>
            </li>
          </viewer>
        </ul>
      </div>
      <div v-if="imgdata.length === 0" class="noMedia">
        <img :src="noData" alt="" />
        暂无图片文件
      </div>
    </div>
    <!-- 视频 -->
    <div v-if="MediaData.type === 2">
      <div class="img-list" v-if="videodata.length > 0">
        <ul>
          <li
            v-for="data in videodata"
            :key="data.id"
            @click="playVideo(data.path)"
          >
            <video :src="data.path"></video>
            <p class="media-date">{{ data.create_time }}</p>
            <Button
              type="error"
              size="small"
              shape="circle"
              class="delPicBtn"
              @click.stop="delMedia(data.id)"
              icon="md-close"
            >
            </Button>
          </li>
        </ul>
      </div>
      <div v-if="videodata.length === 0" class="noMedia">
        <img :src="noData" alt="" />
        暂无视频文件
      </div>
    </div>
    <Page
      :total="total"
      show-total
      v-if="total > 10"
      :current="parseInt(page)"
      :page-size="limit"
      style="text-align: right"
      size="small"
      show-sizer
      :page-size-opts="[10, 15, 20]"
      @on-page-size-change="pageSizeChange"
      @on-change="changePage"
    />

    <!-- 视频窗口 -->
    <article v-show="videoModal">
      <Icon
        type="md-close"
        color="white"
        class="close"
        size="20"
        @click="closeVideoModal()"
      />
      <video controls ref="video" :src="bigVideo" autoplay>
        <!-- <source :src="bigVideo" type="video/mp4" />
        您的浏览器不支持Video标签。 -->
      </video>
    </article>
    <Modal v-model="delModel" :loading="true" @on-ok="delOK" width="300">
      <p style="text-align: center; font-size: 18px; margin: 20px 0 10px">
        确定删除？
      </p>
    </Modal>
  </div>
</template>

<script>
import Api from '@/utils/api.js'
import noData from '@/assets/img/noData.png'
export default {
  data() {
    return {
      teamID: -1,
      MediaUrl: '',
      page: 1,
      total: 0,
      //   showPage: false,
      mediaFormat: ['jpg', 'png', 'jpeg'],
      imgdata: [],
      videodata: [],
      MediaData: {
        // client: 'web',
        type: 1,
        eid: -1,
        folderId: '-1',
      },
      imgModal: false,
      noData,

      bigIndex: 0,
      bigImgList: [],
      bigImgTime: '',

      videoModal: false,
      bigVideo: '',
      delID: '',
      delModel: false,
      limit: 10,
      uploadDisabled: false,
      beUploading: false,
      finish: false,
      percent: 0,
      token: '',
    }
  },

  methods: {
    // 切换tab栏
    changeTab(n) {
      this.MediaData.type = n

      let lis = document.querySelectorAll('.tabs>li')
      lis.forEach((e) => {
        e.setAttribute('class', '')
      })
      lis[n - 1].setAttribute('class', 'active')
      if (n === 1) {
        this.mediaFormat = ['jpg', 'png', 'jpeg']
      } else {
        this.mediaFormat = ['mp4']
      }
      this.page = 1
      this.getMedia()
    },

    // 获取资源
    getMedia() {
      //   this.showPage = false;
      this.$post(Api.getUserMedia(), {
        team_id: this.teamID,
        type: this.MediaData.type,
        page: this.page,
        limit: this.limit,
        client: 'web',
      }).then((res) => {
        if (res.code === 1) {
          this.total = res.data.total
          //   if (this.total > 30) {
          //     setTimeout(() => {
          //       this.showPage = true;
          //     }, 100);
          //   }
          if (this.MediaData.type === 1) {
            // 图片
            this.imgdata = res.data.list
          } else if (this.MediaData.type === 2) {
            this.videodata = res.data.list
          }
        }
      })
    },
    // 翻页
    changePage(p) {
      this.page = p
      this.getMedia()
    },
    pageSizeChange(l) {
      this.limit = l
      this.changePage(1)
    },

    // 关闭弹窗
    closeImgModal() {
      //   document.documentElement.style.overflowY = 'auto';
      this.imgModal = false
      this.bigIndex = 0
      this.bigImgList = []
      this.bigImgTime = ''
    },
    /**
     * 视频
     */
    playVideo(path) {
      document.documentElement.style.overflowY = 'hidden'
      this.bigVideo = path
      this.videoModal = true
    },
    closeVideoModal() {
      document.documentElement.style.overflowY = 'auto'
      this.$refs.video.pause()
      this.bigVideo = ''
      this.videoModal = false
    },
    /**
     * 上传
     */
    // 开始文件上传,禁用上传组件
    onProgress(event, file) {
      this.uploadDisabled = true
      this.beUploading = true
      console.log(event.target)
      event.target.onprogress = (event) => {
        console.log(event)
        // file.showProgress = true;
        let uploadPercent = parseFloat(
          ((event.loaded / event.total) * 100).toFixed(2)
        )
        file.percentage = uploadPercent
        this.percent = uploadPercent
        console.log(uploadPercent)
        if (uploadPercent >= 100) {
          this.beUploading = false
          this.finish = true
        }
      }
    },
    // 上传格式错误
    handleFormatError(file) {
      let tips = ''
      if (this.MediaData.type === 1) {
        tips = '请选择jpg或png格式文件'
      } else {
        tips = '请选择mp4格式文件'
      }
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: tips,
      })
    },
    // 上传失败
    addMediaError(error) {
      this.beUploading = false
      this.uploadDisabled = false
      this.finish = false
      this.$Message.error(error)
      this.getMedia()
    },
    // 上传成功
    addMediaSuccess(response) {
      this.beUploading = false
      if (response.code === 1) {
        this.$Message.info('上传成功')
        this.getMedia()
      } else {
        this.$Message.error(response.msg_customer)
      }
      this.uploadDisabled = false
      this.finish = false
    },
    // 删除
    delMedia(id) {
      this.delID = id
      this.delModel = true
    },
    delOK() {
      this.$post(Api.userMediaDel(), {
        client: 'web',
        media_id: this.delID,
      }).then((res) => {
        this.$Message.info(res.msg_customer)
        if (res.code === 1) {
          this.delModel = false
          this.getMedia()
          this.delID = ''
        }
      })
    },
  },

  mounted() {
    this.MediaUrl = Api.addUserMedia()
    this.token = sessionStorage.getItem('token')
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    if (sessionStorage.getItem('page')) {
      this.page = sessionStorage.getItem('page')
    }
    if (sessionStorage.getItem('team_id') === null) {
      this.teamID = this.userInfo.team_id
      this.getMedia()
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'))
      this.getMedia()
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.teamID = value
      this.changePage(1)
    })
    this.MediaData.eid = sessionStorage.getItem('Eid')
      ? sessionStorage.getItem('Eid')
      : -1
  },
}
</script> 

<style lang="scss" scoped>
.finish {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  text-align: center;
  color: #fff;
  font-size: 20px;
  padding-top: 30vh;
  p {
    margin-bottom: 20px;
  }
}
.media-app {
  width: 100%;
  height: 100%;
  // position: relative;
  header {
    background-color: #464d54;
    color: #fff;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tabs {
      display: inline-flex;
      li {
        padding: 0 16px;
        border-radius: 18px;
        cursor: pointer;
        height: 29px;
        line-height: 29px;
      }
      .active {
        background-color: #fff;
        color: #464d54;
      }
    }
    .tab-btn {
      width: 60px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      background-color: #2196f3;
      text-align: center;
      font-size: 14px;
    }
  }
  .noMedia {
    font-size: 0.16rem;
    text-align: center;
    // margin: 50px 0;
    img {
      margin: 1rem auto 0.2rem;
    }
  }
  // 图片
  .img-list {
    padding: 0.2rem 0 0.15rem 0.28rem;
    overflow: auto;
    height: 7.5rem;
    p {
      font-size: 12px;
      margin-bottom: 10px;
    }
    ul {
      li {
        display: inline-block;
        width: 2rem;
        // max-height: 2.64rem;
        overflow: hidden;
        margin-right: 0.25rem;
        cursor: pointer;
        position: relative;
        img,
        video {
          margin: 0 auto;
          height: 2.34rem;
        }
        button {
          display: none;
          position: absolute;
          bottom: 0.2rem;
          right: 0.05rem;
          opacity: 0;
          transition: all 0.3s linear;
        }
      }
      li:hover button {
        opacity: 1;
        display: block;
      }
    }
  }
  // 视频
  .video-list {
    padding: 0.2rem 0 0.15rem 0.28rem;
    p {
      font-size: 12px;
      margin-bottom: 0.1rem;
    }
    ul {
      li {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        overflow: hidden;
        margin-right: 0.25rem;
        cursor: pointer;
        position: relative;
        img {
          height: 1.5rem;
          margin: 0 auto;
        }
        button {
          position: absolute;
          bottom: -0.35rem;
          right: 0.05rem;
          transition: bottom 0.3s linear;
        }
      }
      li:hover button {
        bottom: 0.05rem;
      }
    }
  }

  .media-date {
    padding: 0.1rem 0;
  }
}
// 弹窗

article {
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 5px;
    cursor: pointer;
    z-index: 99;
    background-color: #000;
    border-radius: 50%;
  }
  video {
    max-height: 100%;
    max-width: 100%;
  }
}
</style>